import { subscriptionInterceptor } from './index';

/* 구독 관리 */
function fetchSubscriptionList(params) {
  return subscriptionInterceptor.post(`/management/list`, params);
}

function fetchSubscriptionDetail(id) {
  return subscriptionInterceptor.get(`/management/${id}`);
}

function insertSubscription(params) {
  return subscriptionInterceptor.post(`/management`, params);
}

function updateSubscription(params) {
  return subscriptionInterceptor.put(`/management`, params);
}

function deleteSubscription(id) {
  return subscriptionInterceptor.delete(`/management/${id}`);
}

/* 알람 관리 */
function fetchAlarmList() {
  return subscriptionInterceptor.get(`/alarm`);
}

function insertAlarm(params) {
  return subscriptionInterceptor.post(`/alarm`, params);
}

/* 알람 조건 관리 */
function fetchRuleList(params) {
  return subscriptionInterceptor.post('/rule/list', params);
}

function fetchRuleDetail(alarmRuleSn) {
  return subscriptionInterceptor.get(`/rule/${alarmRuleSn}`);
}

function deleteRule(alarmRuleSn) {
  return subscriptionInterceptor.delete(`/rule/${alarmRuleSn}`);
}

function fetchExistAlarmRuleId(alarmRuleId) {
  return subscriptionInterceptor.get(`/rule/${alarmRuleId}/exist`);
}

function insertRule(params) {
  return subscriptionInterceptor.post('/rule', params);
}

export {
  fetchSubscriptionList,
  fetchSubscriptionDetail,
  insertSubscription,
  updateSubscription,
  deleteSubscription,
  fetchAlarmList,
  insertAlarm,
  fetchRuleList,
  fetchRuleDetail,
  deleteRule,
  fetchExistAlarmRuleId,
  insertRule,
};

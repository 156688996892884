<template>
  <div>
    <div class="container">
      <SubHeader :pathList="pathList" :title="title" />

      <!-- contents -->
      <div id="contents" class="contents">
        <div class="title-sort-box">
          <h3>{{ $t('subMenu.subscription') }}</h3>
          <div class="sort-side">
            <button
              type="button"
              class="bt-md bt-default"
              @click="goSubscriptionInsert"
            >
              <span>{{ $t('subscription.btn-register') }}</span>
            </button>
          </div>
        </div>
        <list-form
          :fetchApi="fetchSubscriptionList"
          :columns="columns"
          :page="page"
          @detail="selectRow"
          ref="subscriptionList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ListForm from '@/components/common/ListForm.vue';
import CommonMixins from '@/mixins/CommonMixins.js';
import { fetchSubscriptionList } from '@/api/subscriptionApi';

export default {
  components: {
    ListForm,
  },
  mixins: [CommonMixins],
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.subscription'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      columns: [
        {
          label: this.$i18n.t('subscription.table-column1'),
          field: 'resourceId',
          width: '10%',
          sortable: false,
        },
        {
          label: this.$i18n.t('subscription.table-column2'),
          field: 'criteria',
          width: '30%',
        },
        {
          label: this.$i18n.t('subscription.table-column3'),
          field: 'channelEndpoint',
          width: '30%',
          sortable: false,
          formatFn: val => this.setEndpoint(val),
        },
        {
          label: this.$i18n.t('subscription.table-column4'),
          field: 'endDate',
          width: '20%',
          sortable: false,
          formatFn: val => CommonMixins.methods.common_getDateString(val),
        },
        {
          label: this.$i18n.t('subscription.table-column5'),
          field: 'status',
          width: '10%',
          formatFn: val => (val === 'active' ? 'ON' : 'OFF'),
        },
      ],
      page: this.$route?.params?.page || 1,
    };
  },

  methods: {
    // 구독 목록 조회
    fetchSubscriptionList(param) {
      return fetchSubscriptionList(param);
    },

    // endpoint value set
    setEndpoint(endpotint) {
      if (
        (location.host.startsWith('wwwaz') ||
          location.host.startsWith('localhost')) &&
        endpotint === 'https://ruleaz.redwoodhealth.kr/Subscription'
      ) {
        return 'REDWOOD RULE-ENGINE URL';
      } else if (
        location.host.startsWith('www') &&
        endpotint === 'https://rule.redwoodhealth.kr/Subscription'
      ) {
        return 'REDWOOD RULE-ENGINE URL';
      } else {
        return endpotint;
      }
    },

    // 목록에서 row 선택
    selectRow({ resourceId, channelEndpoint, page }) {
      if (
        channelEndpoint === 'https://ruleaz.redwoodhealth.kr/Subscription' ||
        channelEndpoint === 'https://rule.redwoodhealth.kr/Subscription'
      ) {
        // endpoint가 룰엔진 -> 알람 관리 메뉴로 이동
        this.$router.push({ name: 'alarm' });
      } else {
        // else -> 상세 정보 표시(수정 화면)
        this.goSubscriptionDetail(resourceId, page);
      }
    },

    // 구독 상세 화면 이동
    goSubscriptionDetail(resourceId, page) {
      this.$router.push({
        name: 'subscriptionInsert',
        params: { type: 'update', resourceId, page },
      });
    },

    // 구독 등록 화면 이동
    goSubscriptionInsert() {
      this.$router.push({
        name: 'subscriptionInsert',
        params: { type: 'insert', page: 0 },
      });
    },
  },
};
</script>
<style></style>
